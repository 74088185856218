import { LightgalleryItem } from "react-lightgallery";
import React from "react";
import ModalWindow from "../components/ModalWindow";
import BlockInfo from "../components/BlockInfo";
import ListOffices from "../components/ListOffices";

const objectsBD = [
  {
    id: 24,
    title: "ЖК Литер2",
    desc: "Многоквартирный жилой дом в 121 квартале г.Благовещенска",
    new: true,
    images: [
      "images/objects/24/1.jpg",
      "images/objects/24/2.jpg",
      "images/objects/24/3.jpg",
      "images/objects/24/4.jpg",
    ],
    tabs: [
      {
        label: "План 1-го этажа",
        content:
          <ModalWindow img={"images/objects/24/tab1.jpg"}/>,
      },
      {
        label: "План 2-6 этажей",
        content:
          <ModalWindow img={"images/objects/24/tab2.jpg"}/>,
      },
      {
        label: "План 7-12 этажа",
        content:
          <ModalWindow img={"images/objects/24/tab3.jpg"}/>,
      },
    ],
  },
  {
    id: 23,
    title: "ЖК Литер2",
    desc: "Многоквартирный жилой дом в 5 квартале г.Благовещенска",
    new: true,
    images: [
      "images/objects/23/1.jpg",
      "images/objects/23/2.jpg",
      "images/objects/23/5.jpg",
      "images/objects/23/6.jpg",
      "images/objects/23/7.jpg",
    ],
    tabs: [
      {
        label: "План 1-го этажа",
        content:
          <ModalWindow img={"images/objects/23/tab1.png"}/>,
      },
      {
        label: "План 2-5 этажей",
        content:
          <ModalWindow img={"images/objects/23/tab2.png"}/>,
      },
      {
        label: "План 6-го этажа",
        content:
          <ModalWindow img={"images/objects/23/tab3.png"}/>,
      },
      {
        label: "План 7-12 этажей",
        content:
          <ModalWindow img={"images/objects/23/tab4.png"}/>,
      },
    ],
  },
  {
    id: 22,
    title: "ЖК Литер1",
    desc: "Многоквартирный жилой дом в 5 квартале г.Благовещенска",
    new: true,
    images: [
      "images/objects/22/1.jpg",
      "images/objects/22/2.jpg",
      "images/objects/22/5.jpg",
      "images/objects/22/6.jpg",
      "images/objects/22/7.jpg",
    ],
    tabs: [
      {
        label: "План 1-го этажа",
        content:
          <ModalWindow img={"images/objects/22/tab1.png"}/>,
      },
      {
        label: "План 2-5 этажей",
        content:
          <ModalWindow img={"images/objects/22/tab2.png"}/>,
      },
      {
        label: "План 6-го этажа",
        content:
          <ModalWindow img={"images/objects/22/tab3.png"}/>,
      },
      {
        label: "План 7-12 этажей",
        content:
          <ModalWindow img={"images/objects/22/tab4.png"}/>,
      },
    ],
  },

  {
    id: 21,
    title: "ПРОДАЖА ОФИСОВ",
    desc: "",
    new: true,
    office: true,
    images: [
      "images/objects/21/1.jpg",
    ],

    tabs: [
      {
        label: "Комсомольская 89",
        content: <ListOffices list={
          [
            {
              name: "Офис1",
              stage: "Подвал",
              square: "177.4",
              price: "8 870 000",
            },
          ]
        }/>,

      },
      {
        label: "Красноармейская 164",
        content: <ListOffices list={
          [
            {
              name: "Офис1",
              stage: "Подвал",
              square: "102.5",
              price: "7 700 000",
            },
            {
              name: "Офис2",
              stage: "Подвал",
              square: "110.3",
              price: "8 280 000",
            },
            {
              name: "Офис3",
              stage: "Подвал",
              square: "111.5",
              price: "8 360 000",
            },
            {
              name: "Офис4",
              stage: "Подвал",
              square: "106.9",
              price: "8 020 000",
            },
          ]
        }/>,

      },
    ],
  },

  {
    id: 20,
    title: "ЖК",
    desc: "Многоквартирный жилой дом в 121 квартале г.Благовещенска",
    new: false,
    images: [
      "images/objects/20/1.jpg",
      "images/objects/20/2.jpg",
      "images/objects/20/3.jpg",
    ],
    tabs: [
      {
        label: "1-й этаж",
        content:
          <ModalWindow img={"images/objects/20/plan_1.jpg"}/>,
      },
      {
        label: "2-5 этажи",
        content:
          <ModalWindow img={"images/objects/20/plan_2.jpg"}/>,
      },
      {
        label: "6-8 этажи",
        content:
          <ModalWindow img={"images/objects/20/plan_3.jpg"}/>,
      },
      {
        label: "9-12 этажи",
        content:
          <ModalWindow img={"images/objects/20/plan_4.jpg"}/>,
      },
    ],
  },

  {
    id: 1,
    title: "ЖК СВЕТЛЫЙ ЛИТЕР 4",
    desc: "Многоквартирный жилой дом в 425 квартале Литер 4 г.Благовещенска",
    new: true,
    images: [
      "images/objects/1/1.jpg",
      "images/objects/1/2.jpg",
      "images/objects/1/3.jpg",
    ],
    tabs: [
      {
        label: "1-й этаж",
        content:
          <ModalWindow img={"images/objects/1/plan_1.png"}/>,
      },
      {
        label: "2-5 этажи",
        content:
          <ModalWindow img={"images/objects/1/plan_2.png"}/>,
      },
      {
        label: "6-10 этажи",
        content:
          <ModalWindow img={"images/objects/1/plan_3.png"}/>,
      },
    ],
  },
  {
    id: 2,
    title: "ЖК АЛЕКСЕЕВСКИЙ",
    desc: "Многоквартирный жилой дом со встроенными помещениями общественного назначения в 168 квартале Литер 1 г.Благовещенска",
    new: true,
    images: [
      "images/objects/2/1.jpg",
      "images/objects/2/2.jpg",
      "images/objects/2/3.jpg",
    ],
    tabs: [
      {
        label: "Блок-секция А (2,3,6-й этажи)",
        content:
          <ModalWindow img={"images/objects/2/plan_1.jpg"}/>,
      },
      {
        label: "Блок-секция Б (2-й этаж)",
        content:
          <ModalWindow img={"images/objects/2/plan_2.jpg"}/>,
      },
      {
        label: "Блок-секция Б (3-8-й этажи)",
        content:
          <ModalWindow img={"images/objects/2/plan_3.jpg"}/>,
      },
    ],
  },
  {
    id: 3,
    title: "114 КВАРТАЛ",
    desc: "Жилой комплекс \"Горизонт\". Многоквартирный жилой дом со встроенными помещениями общественного назначения в 114 квартале г.Благовещенска",
    new: false,
    images: [
      "images/objects/3/1.jpg",
      "images/objects/3/2.jpg",
      "images/objects/3/3.jpg",
    ],
    tabs: [
      {
        label: "Этапы строительства",
        content:
          <ModalWindow img={"images/objects/3/tab1.jpg"}/>,
      },
      {
        label: "2-6 этажи",
        content:
          <ModalWindow img={"images/objects/3/tab2.jpg"}/>,
      },
    ],
  },
  {
    id: 4,
    title: "425 квартал Литер 3",
    desc:
      <div>
        <div>Жилой комплекс "Светлый".</div>
        <div>Многоквартирный жилой дом со встроенными помещениями общественного назначения в 425 квартале Литер 3
          г.Благовещенска
        </div>
        <div>Дата ввода в эксплуатацию - 28.09.2020г.</div>
      </div>,
    new: false,
    images: [
      "images/objects/4/1.jpg",
      "images/objects/4/2.jpg",
      "images/objects/4/3.jpg",
    ],
    tabs: [
      {
        label: "1-й этаж",
        content:
          <ModalWindow img={"images/objects/4/tab1.jpg"}/>,
      },
      {
        label: "2-5 этажи",
        content:
          <ModalWindow img={"images/objects/4/tab2.jpg"}/>,
      },
      {
        label: "6-10 этажи",
        content:
          <ModalWindow img={"images/objects/4/tab3.jpg"}/>,
      },
    ],
  },
  {
    id: 5,
    title: "425 КВАРТАЛ ЛИТЕР 2",
    desc:
      <div>
        <div>Жилой комплекс "Светлый".</div>
        <div>Многоквартирный жилой дом со встроенными помещениями общественного назначения в 425 квартале Литер 2
          г.Благовещенска
        </div>
        <div>Дата ввода в эксплуатацию - 09.06.2020г.</div>
      </div>,
    new: false,
    images: [
      "images/objects/5/1.jpg",
      "images/objects/5/2.jpg",
      "images/objects/5/3.jpg",
    ],
    tabs: [
      {
        label: "1-й этаж",
        content:
          <ModalWindow img={"images/objects/5/tab1.jpg"}/>,
      },
      {
        label: "2-5 этажи",
        content:
          <ModalWindow img={"images/objects/5/tab2.jpg"}/>,
      },
      {
        label: "6-10 этажи",
        content:
          <ModalWindow img={"images/objects/5/tab3.jpg"}/>,
      },
    ],
  },
  {
    id: 6,
    title: "Комсомольская 89 (6-й подъезд)",
    desc:
      <div>
        <div>Многоквартирный жилой дом в 150 квартале г.Благовещенска.</div>
      </div>,
    new: false,
    images: [
      "images/objects/6/1.jpg",
    ],
    tabs: [
      {
        label: "1-й этаж",
        content:
          <ModalWindow img={"images/objects/6/tab1.jpg"}/>,
      },
      {
        label: "2-7 этажи",
        content:
          <ModalWindow img={"images/objects/6/tab2.jpg"}/>,
      },

    ],
  },
  {
    id: 7,
    title: "425 квартал Литер 1",
    desc:
      <div>
        <div>Жилой комплекс "Светлый".</div>
        <div>Многоквартирный жилой дом со встроенными помещениями общественного назначения в 425 квартале Литер 1
          г.Благовещенска
        </div>
      </div>,
    new: false,
    images: [
      "images/objects/7/1.jpg",
      "images/objects/7/2.jpg",
      "images/objects/7/3.jpg",
    ],
    tabs: [
      {
        label: "Планировка",
        content:
          <ModalWindow img={"images/objects/7/tab1.jpg"}/>,
      },
    ],
  },
  {
    id: 8,
    title: "120 квартал Литер 2",
    desc:
      <div>
        <div>Жилой комплекс "Новый".</div>
        <div>Многоквартирный жилой дом со встроенными помещениями общественного назначения в 120 квартале Литер 2
          г.Благовещенска
        </div>
        <div>Дата ввода в эксплуатацию - 3-й квартал 2018г.</div>
      </div>,
    new: false,
    images: [
      "images/objects/8/1.jpg",
      "images/objects/8/2.jpg",
      "images/objects/8/3.jpg",
    ],
    tabs: [
      {
        label: "1-й этаж",
        content:
          <ModalWindow img={"images/objects/8/tab1.jpg"}/>,
      },
      {
        label: "2-7 этажи",
        content:
          <ModalWindow img={"images/objects/8/tab2.png"}/>,
      },
      {
        label: "8-14 этажи",
        content:
          <ModalWindow img={"images/objects/8/tab3.png"}/>,
      },


    ],
  },
  {
    id: 9,
    title: "120 квартал Литер 3",
    desc:
      <div>
        <div>Жилой комплекс "Новый".</div>
        <div>Многоквартирный жилой дом со встроенными помещениями общественного назначения в 120 квартале Литер 3
          г.Благовещенска
        </div>
      </div>,
    new: false,
    images: [
      "images/objects/9/1.jpg",
      "images/objects/9/2.jpg",
      "images/objects/9/3.jpg",
    ],
    tabs: [
      {
        label: "Планировки",
        content:
          <ModalWindow img={"images/objects/9/tab1.jpg"}/>,
      },
    ],
  },
  {
    id: 10,
    title: "120 квартал",
    desc:
      <div>
        <div>Жилой комплекс "Новый".</div>
        <div>Многоквартирный жилой дом со встроенными помещениями общественного назначения в 120 квартале
          г.Благовещенска
        </div>
        <div>Начало строительства - 2015г.</div>
        <div>Дата ввода в эксплуатацию - август 2017г.</div>
      </div>,
    new: false,
    images: [
      "images/objects/10/1.jpg",
    ],
    tabs: [
      {
        label: "План 1-го этажа",
        content:
          <ModalWindow img={"images/objects/10/tab1.jpg"}/>,
      },
      {
        label: "План типового этажа",
        content:
          <ModalWindow img={"images/objects/10/tab2.jpg"}/>,
      },
    ],
  },
  {
    id: 11,
    title: "ул.Зейская 53",
    desc:
      <div>
        <div>Многоквартирный жилой дом в 87 квартале г.Благовещенска</div>
        <div>Начало строительства - 2009г.</div>
        <div>Дата ввода в эксплуатацию - август 2012г.</div>
      </div>,
    new: false,
    images: [
      "images/objects/11/1.jpg",
    ],
  },
  {
    id: 12,
    title: "ул.Зейская 53/1",
    desc:
      <div>
        <div>Многоквартирный жилой дом в 87 квартале г.Благовещенска</div>
        <div>Начало строительства - 2006г.</div>
        <div>Дата ввода в эксплуатацию - 2009г.</div>
      </div>,
    new: false,
    images: [
      "images/objects/12/1.jpg",
    ],
  },
  {
    id: 13,
    title: "ул.Горького 152",
    desc:
      <div>
        <div>Многоквартирный жилой дом со встроенными помещениями общественного назначения в 45 квартале
          г.Благовещенска
        </div>
        <div>Начало строительства - 2002г.</div>
        <div>Дата ввода в эксплуатацию - 2005г.</div>
      </div>,
    new: false,
    images: [
      "images/objects/13/1.jpg",
    ],
  },
  {
    id: 14,
    title: "ул.Зейская 34/4",
    desc:
      <div>
        <div>Многоквартирный жилой дом в 87 квартале г.Благовещенска</div>
        <div>Начало строительства - 2004г.</div>
        <div>Дата ввода в эксплуатацию -</div>
        <div>2006г - 1 очередь,</div>
        <div>2007г - 2 очередь,</div>
        <div>2008г - 3 очередь.</div>
      </div>,
    new: false,
    images: [
      "images/objects/14/1.jpg",
    ],
  },
  {
    id: 15,
    title: "34 квартал",
    desc:
      <div>
        <div>Многоквартирный жилой дом со встроенными помещениями общественного назначения в 34 квартале
          г.Благовещенска
        </div>
        <div>Начало строительства - июль 2014г.</div>
        <div>Дом сдан 02.08.16г</div>
      </div>,
    new: false,
    images: [
      "images/objects/15/1.jpg",
      "images/objects/15/2.jpg",
      "images/objects/15/3.jpg",
    ],
    tabs: [
      {
        label: "План 1-го этажа",
        content:
          <ModalWindow img={"images/objects/15/tab1.jpg"}/>,
      },
      {
        label: "План 2-го этажа",
        content:
          <ModalWindow img={"images/objects/15/tab2.jpg"}/>,
      },
      {
        label: "План 3-9 этажей",
        content:
          <ModalWindow img={"images/objects/15/tab3.jpg"}/>,
      },
      {
        label: "План 10-14 этажей",
        content:
          <ModalWindow img={"images/objects/15/tab4.jpg"}/>,
      },
    ],
  },
  {
    id: 16,
    title: "ул.Забурхановская 85",
    desc:
      <div>
        <div>Многоквартирный жилой дом со встроенными помещениями общественного назначения в квартале г.Благовещенска
        </div>
        <div>Начало строительства - 2000г.</div>
        <div>Дата ввода в эксплуатацию - 2002г.</div>
      </div>,
    new: false,
    images: [
      "images/objects/16/1.jpg",
    ],
  },
  {
    id: 17,
    title: "ул.Фрунзе 48",
    desc:
      <div>
        <div>Многоквартирный жилой дом со встроенными помещениями общественного назначения в 84 квартале г.Благовещенска
        </div>
        <div>Начало строительства - 2005г.</div>
        <div>Дата ввода в эксплуатацию - 2009г.</div>
      </div>,
    new: false,
    images: [
      "images/objects/17/1.jpg",
    ],
  },
  {
    id: 18,
    title: "ул.Шевченко 65",
    desc:
      <div>
        <div>Многоквартирный жилой дом со встроенными помещениями общественного назначения в 168 квартале
          г.Благовещенска
        </div>
        <div>Начало строительства - 2005г.</div>
        <div>Дата ввода в эксплуатацию - 2006г.</div>
      </div>,
    new: false,
    images: [
      "images/objects/18/1.jpg",
    ],
  },
  {
    id: 19,
    title: "ул.Б.Хмельницкого 21",
    desc:
      <div>
      </div>,
    new: false,
    images: [
      "images/objects/19/1.jpg",
    ],
  },

];


export default objectsBD;